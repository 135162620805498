import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import LoadTop from '../components/LoadTop';
import BackToTop from '../components/BackToTop';
import HomeOne from "./HomeOne";
import HomeTwo from "./HomeTwo";
import HomeThree from "./HomeThree";
import HomeFour from "./HomeFour";
import HomeFive from "./HomeFive";
import HomeSix from "./HomeSix";
import HomeSeven from "./HomeSeven";
import HomeEight from "./HomeEight";
import HomeNine from "./HomeNine";
import HomeTen from "./HomeTen";
// inner pages
import OurService from "../inner/OurService";
import ServiceTwo from "../inner/ServiceTwo";
import ServiceThree from "../inner/ServiceThree";
import ServiceDetails from "../inner/ServiceDetails";
import Appoinment from "../inner/Appoinment";
import TestimonialsOne from "../inner/TestimonialsOne";
import Error from "../inner/Error";
import Project from "../inner/Project";
import ProjectTwo from "../inner/ProjectTwo";
import ProjectThree from "../inner/ProjectThree";
import ProjectFour from "../inner/ProjectFour";
import ProjectFive from "../inner/ProjectFive";
import ProjectDetails from "../inner/ProjectDetails";
import Team from "../inner/Team";
import TeamTwo from "../inner/TeamTwo";
import TeamThree from "../inner/TeamThree";
import TeamFour from "../inner/TeamFour";
import TeamFive from "../inner/TeamFive";
import TeamDetails from "../inner/TeamDetails";
import BlogList from "../inner/BlogList";
import BlogGrid from '../inner/BlogGrid';
import BlogDetails from '../inner/BlogDetails';
import BlogDetailsDefault from '../inner/BlogDetailsDefault';
import ContactUs from '../inner/ContactUs';

// onepage
import OnepageOne from '../onepage/OnepageOne';
import OnepageTwo from '../onepage/OnepageTwo';
import OnepageThree from '../onepage/OnepageThree';
import OnepageFour from '../onepage/OnepageFour';
import OnepageFIve from '../onepage/OnepageFIve';
import OnepageSix from '../onepage/OnepageSix';
import OnepageSeven from '../onepage/OnepageSeven';
import OnepageEight from '../onepage/OnepageEight';
import OnepageNine from '../onepage/OnepageNine';
import OnepageTen from '../onepage/OnepageTen';
import MilitaryDefence from '../industries/MilitaryDefence';
import Healthcare from '../industries/Healthcare';
import Aerospace from '../industries/Aerospace';
import Railways from '../industries/Railways';
import GovernmentPsu from '../industries/GovernmentPsu';
import AboutUs from '../aboutus/AboutUs';
import OurVision from '../aboutus/OurVision';
import OurMission from '../aboutus/OurMission';
import RuggedComputers from '../products/IndustialComputers';
import RuggedServers from '../products/RuggedServers';
import RuggedDisplays from '../products/RuggedDisplays';
import RuggedKvmDisplays from '../products/RuggedKvmDisplays';
import RuggedPanelPcs from '../products/RuggedPanelPcs';
import RuggedWorkstations from '../products/RuggedWorkstations';
import RuggedEthernetSwitches from '../products/RuggedEthernetSwitches';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import Disclaimer from '../pages/Disclaimer';
import Products from '../pages/Products';
import Industries from '../pages/Industries';
import Customers from '../pages/Customers';
import CustomerSupport from '../pages/CustomerSupport';
import Partners from '../pages/Partners';
import Careers from '../pages/Careers';
import SupplyChainManagement from '../industries/SupplyChainManagement';
import ComponentRepair from '../industries/ComponentRepair';
import CharterService from '../industries/CharterService';
import InsuranceServices from '../industries/InsuranceServices';
import AircraftSalePurchaseLease from '../industries/AircraftSalePurchaseLease';
import ManpowerSupport from '../industries/ManpowerSupport';
import CamoSolutions from '../industries/CamoSolutions';
import GroundHandlingServices from '../industries/GroundHandlingServies';
import AvionicsInstallation from '../industries/AvionicsInstallation';
import AogSupportService from '../industries/AogServiceSupport';
import EngineeringCertification from '../industries/EngineeringCertification';
import SparePartsRetableSales from '../industries/SparePartsRetableSales';
import AviationProducts from '../products/AviationProducts';




function RouterPage() {
    return (
        <div>
            <Router>
                <LoadTop />
                <Routes>
                <Route path="/" element={<HomeOne />}></Route>
                    {/* <Route path="/home-one" element={<HomeOne />}></Route> */}
                    <Route path="/home-two" element={<HomeTwo />}></Route>
                    <Route path="/military-defence" element={<MilitaryDefence />}></Route>
                    <Route path="/healthcare" element={<Healthcare />}></Route>
                    <Route path="/aerospace" element={<Aerospace />}></Route>
                    <Route path="/railways" element={<Railways />}></Route>
                    <Route path="/government-psu" element={<GovernmentPsu />}></Route>
                    <Route path="/aviation-products" element={<AviationProducts />}></Route>
                    <Route path="/rugged-computers" element={<RuggedComputers />}></Route>
                    <Route path="/rugged-servers" element={<RuggedServers />}></Route>
                    <Route path="/rugged-displays" element={<RuggedDisplays />}></Route>
                    <Route path="/rugged-kvm-displays" element={<RuggedKvmDisplays />}></Route>
                    <Route path="/rugged-panel-pcs" element={<RuggedPanelPcs />}></Route>
                    <Route path="/rugged-workstations" element={<RuggedWorkstations />}></Route>
                    <Route path="/rugged-ethernet-switches" element={<RuggedEthernetSwitches />}></Route>
                    <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                    <Route path="/disclaimer" element={<Disclaimer />}></Route>
                    <Route path="/about-us" element={<AboutUs />}></Route>
                    <Route path="/our-vision" element={<OurVision />}></Route>
                    <Route path="/our-mission" element={<OurMission />}></Route>
                    <Route path="/products" element={<Products />}></Route>
                    <Route path="/industries" element={<Industries />}></Route>
                    <Route path="/customers" element={<Customers />}></Route>
                    <Route path="/partners" element={<Partners />}></Route>
                    <Route path="/customer-support" element={<CustomerSupport />}></Route>
                    <Route path="/careers" element={<Careers />}></Route>
                    <Route path="/supply-chain-management" element={<SupplyChainManagement />}></Route>
                    <Route path="/component-repair-rental-services" element={<ComponentRepair />}></Route>
                    <Route path="/charter-service" element={<CharterService />}></Route>
                    <Route path="/insurance-services" element={<InsuranceServices />}></Route>
                    <Route path="/charter-service" element={<CharterService />}></Route>
                    <Route path="/aircraft-sale-purchase-lease" element={<AircraftSalePurchaseLease />}></Route>
                    <Route path="/camo-solutions" element={<CamoSolutions />}></Route>

                    <Route path="/ground-handling-services" element={<GroundHandlingServices />}></Route>
                    <Route path="/avionics-installation" element={<AvionicsInstallation />}></Route>
                    <Route path="/aog-support-service" element={<AogSupportService />}></Route>
                    <Route path="/engineering-and-certification" element={<EngineeringCertification />}></Route>
                    <Route path="/spare-parts-retable-sales" element={<SparePartsRetableSales />}></Route>




                    <Route path="/manpower-support" element={<ManpowerSupport />}></Route>

                    <Route path="/home-three" element={<HomeThree />}></Route>
                    <Route path="/home-four" element={<HomeFour />}></Route>
                    <Route path="/home-five" element={<HomeFive />}></Route>
                    <Route path="/home-six" element={<HomeSix />}></Route>
                    <Route path="/home-seven" element={<HomeSeven />}></Route>
                    <Route path="/home-eight" element={<HomeEight />}></Route>
                    <Route path="/home-nine" element={<HomeNine />}></Route>
                    <Route path="/home-ten" element={<HomeTen />}></Route>
                    <Route path="/our-service" element={<OurService />}></Route>
                    <Route path="/service-2" element={<ServiceTwo />}></Route>
                    <Route path="/service-3" element={<ServiceThree />}></Route>
                    <Route path="/service-details" element={<ServiceDetails />}></Route>
                    <Route path="/appoinment" element={<Appoinment />}></Route>
                  
                    <Route path="/testimonial-style-1" element={<TestimonialsOne />}></Route>
                    <Route path="/404" element={<Error />}></Route>
                    <Route path="/project" element={<Project />}></Route>
                    <Route path="/portfolio-style-2" element={<ProjectTwo />}></Route>
                    <Route path="/portfolio-style-3" element={<ProjectThree />}></Route>
                    <Route path="/portfolio-style-4" element={<ProjectFour />}></Route>
                    <Route path="/portfolio-style-5" element={<ProjectFive />}></Route>
                    <Route path="/project-details" element={<ProjectDetails />}></Route>
                    <Route path="/team" element={<Team />}></Route>
                    <Route path="/team-style-2" element={<TeamTwo />}></Route>
                    <Route path="/team-style-3" element={<TeamThree />}></Route>
                    <Route path="/team-style-4" element={<TeamFour />}></Route>
                    <Route path="/team-style-5" element={<TeamFive />}></Route>
                    <Route path="/team-details" element={<TeamDetails />}></Route>
                    <Route path="/blog-list" element={<BlogList />}></Route>
                    <Route path="/blog-grid" element={<BlogGrid />}></Route>
                    <Route path="/blog-details" element={<BlogDetails />}></Route>
                    <Route path="/blog/:id" element={<BlogDetails />}></Route>
                    <Route path="/blog-details-default" element={<BlogDetailsDefault />}></Route>
                    <Route path="/contactus" element={<ContactUs />}></Route>
                    {/* onepage */}
                    <Route path="/onepage-one" element={<OnepageOne />}></Route>
                    <Route path="/onepage-two" element={<OnepageTwo />}></Route>
                    <Route path="/onepage-three" element={<OnepageThree />}></Route>
                    <Route path="/onepage-four" element={<OnepageFour />}></Route>
                    <Route path="/onepage-five" element={<OnepageFIve />}></Route>
                    <Route path="/onepage-six" element={<OnepageSix />}></Route>
                    <Route path="/onepage-seven" element={<OnepageSeven />}></Route>
                    <Route path="/onepage-eight" element={<OnepageEight />}></Route>
                    <Route path="/onepage-nine" element={<OnepageNine />}></Route>
                    <Route path="/onepage-ten" element={<OnepageTen />}></Route>
                </Routes>
                <BackToTop />
            </Router>
        </div>
    )
}

export default RouterPage