import React from 'react'
import HeaderOne from "../components/header/HeaderOne";

import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';

const AircraftSalePurchaseLease = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Aircraft Purchase, Sale & Leasing' }
    ];
  return (
   <>    
   
<div className=''>
<HeaderOne />
<AviationBreadcrumb title="Aircraft Purchase, Sale & Leasing" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/aircraft-purchase-sale-leasing.jpg" alt="Supply Chain Management" />
                    </div>
                   </div>  
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <h4 className="title"> Aircraft Purchase, Sale & Leasing </h4>
                    <p className='desc'> <b>  “Service with Trust”. At P.A.S. </b> we specialize in aircraft purchase, sale, and leasing services with a commitment to excellence and global reach. Whether you're acquiring a new jet, selling your aircraft, or exploring flexible leasing options, our expert team ensures a seamless and professional experience. 
                        Trust us to deliver tailored solutions that meet your aviation needs.  </p>
                </div>  
            </div>
        </div>
    </div>
</div>



<CallToActionThree />

<PrimaryFooter />
</div>    
   
   
   
   </>
  )
}

export default AircraftSalePurchaseLease