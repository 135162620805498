import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";


import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import ProductsSidebar from './ProductsSidebar';

const AviationProducts = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Aviation Products & Solutions' }
    ];
  return (
    <>  
     
    <div className=''>
<HeaderOne />
<Breadcrumb title="Aviation Products & Solutions" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <img src='assets/images/aviation-product.jpg' className='img-fluid' alt=''/>
                    <h4 className="title"> Aviation Products & Solutions </h4>
                    <p className="disc"> High-Quality Aviation Equipment & Supplies: Welcome to Planetronix , your trusted partner for premium aviation products. 
                        Whether you're an aircraft operator, maintenance professional, or aviation enthusiast, we provide top-tier solutions to keep you flying safely and efficiently. </p>
                </div>  



            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">          
<ProductsSidebar />

                </div>
            {/* rts- blog wizered end area */}
        </div>

        <div className='row'> 
<h4 className="title text-center"> Our Product Categories: </h4>
    <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='description'> 
        <h5> Aircraft Parts & Components </h5>
     <ul>
<li> Certified OEM and aftermarket parts  </li>
<li> Avionics and electrical systems  </li>
<li> Engine components and accessories </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='description'> 
        <h5> Maintenance & Ground Support Equipment </h5>
     <ul>
<li> Aircraft jacks, stands, and tugs  </li>
<li> De-icing and fueling systems  </li>
<li> Tools and repair kits </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='description'> 
        <h5> Pilot & Crew Essentials </h5>
     <ul>
<li> Flight bags and headsets  </li>
<li> Navigation tools and charts  </li>
<li> Safety gear and apparel </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='description'> 
        <h5> Innovative Aviation Technologies </h5>
     <ul>
<li> Advanced avionics and GPS solutions  </li>
<li> Weather radar and communication systems  </li>
<li> AI-powered flight data analysis </li>
     </ul>
     </div>
         </div>

 

     </div>




    </div>
</div>
{/* End service details area */}

<CallToActionThree />

<PrimaryFooter />
</div>
    </>
  )
}

export default AviationProducts
