import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const GroundHandlingServices = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Components Repair & Rental Services' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Ground handling services" breadcrumbs={breadcrumbs} />


{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                    <img src="assets/images/ground-handling-services.jpg" alt="Ground handling services" />
                    </div>
                   
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>



<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
<h4 className="title text-center"> Ground Handling Services  </h4>
                   <p className='pb-5'> “Your Trusted Partner in Seamless Ground Handling Services”. Through our wide network we at P.A.S. can provide comprehensive ground handling services for airlines and airports worldwide. 
                    With years of experience and a commitment to excellence, our partner delivers tailored solutions to ensure smooth operations, on-time performance, and exceptional passenger experiences. 
                     We offer a full range of ground handling services through our network designed to meet the unique needs of our clients. The services include: </p>
            </div> 

            <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Passenger Services </h5>
       <ul>
        <li> Check-in and boarding assistance </li>
        <li> Baggage handling and tracking </li>
        <li> Special assistance for passengers with reduced mobility </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Ramp Services </h5>
       <ul>
        <li> Aircraft marshalling and towing </li>
        <li> Loading and unloading of cargo and baggage </li>
        <li> Ground power and air conditioning </li>
       </ul>
       </div> 
   </div>



   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Cargo and Mail Handling </h5>
       <ul>
        <li> Secure and efficient cargo loading/unloading </li>
        <li> Mail handling and logistics support </li>
       </ul>
       </div> 
   </div>



   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Aircraft Servicing </h5>
       <ul>
        <li> Refueling and de-icing </li>
        <li> Water and waste management </li>
        <li> Catering coordination </li>
       </ul>
       </div> 
   </div>



   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Operational Support </h5>
       <ul>
        <li> Flight operations and dispatch services </li>
        <li> Coordination with air traffic control </li>
       </ul>
       </div> 
   </div>



   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Security Services </h5>
       <ul>
        <li> Aircraft and baggage security screening </li>
        <li> Compliance with international safety standards </li>
       </ul>
       </div> 
   </div>

        </div>
    </div>
</div>
{/* End service details area */}



<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default GroundHandlingServices
