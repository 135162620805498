import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import ContactForm from "../components/contactform/ContactForm";
import MapOne from "../components/map/MapOne";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import HomeProductSlider from '../products/HomeProductSlider';
import BrandOne from '../components/brand/BrandOne';

function HomeOne() {
  return (
    <div>
      <HeaderOne />
    	<section className="creative-hero--section">
        <div className="bg bg-image"></div>
        <div className="bg-overlay"></div>
        <div className="auto-container">
            <div className="content-box">
                <span className="hero-subtitle"> Welcome! Planetronix Consulting Private Limited  </span>
                <h1 className="hero-title"> Your Business Grows  
                With Great Supply Chain Support Solutions  </h1>
                <div className="hero-desc"> Reclaim Your Energy, Confidence, and Joy You are not alone, and you deserve better. </div>
                <div className="btn-box">
                    <a className="hero-cta" href="#">
                        <span className="cta-title"> Book Your Free Consultation Now <i className="fa fa-arrow-right"></i></span>
                    </a>
                </div>

            </div>
        </div>
    </section> 



      
      <section className='aboutsec'>
    <div><h2> About us </h2>
    <p> Planetronix is a homegrown manufacturer of bespoke, rugged systems, and they also serve as a partner for numerous international Original Equipment Manufacturers (OEMs) for sales, support, and spare parts management in India. </p> 
    <Link to="/about-us">   <button className='button'> View more </button> </Link>
    </div>

    <div><h2> Vision </h2>
    <p> To offer effective services without relinquishing quality and to fulfil the needs of the client by developing their business, holistically. We intend to help the business accomplish a remarkable exhibition online through our imaginative approach.  </p> 
    <Link to="/our-vision">   <button className='button'> View more </button> </Link>
    </div>

    <div><h2> Mission </h2>
    <p> To give our clients the most compelling experience possible and build a long term relationship by becoming one of the top IT solution providers worldwide. </p>
    <Link to="/our-mission">   <button className='button'> View more </button> </Link>
     </div>
</section>

<HomeProductSlider />

<BrandOne />
    
      <ContactForm />
      <MapOne />
      <CallToActionThree />
      
<PrimaryFooter />
    </div>
  )
}

export default HomeOne