import React from 'react'
import { Link } from 'react-router-dom';

const AviationSidebar = () => {
  return (
    <>
    
        
    <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Aviation Services </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
             <li> <Link to={'/supply-chain-management'}> Supply Chain Management <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/spare-parts-retable-sales'}> Spare Parts & Rotables Sales <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/component-repair-rental-services'}> Components Repair & Rental Services <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/aog-support-service'}> AOG Service & Support <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/avionics-installation'}> Avionics Installation <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/engineering-and-certification'}>  Engineering & Certification/STCs <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/insurance-services'}> Insurance Services <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/charter-service'}>  Charter Service <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/aircraft-sale-purchase-lease'}> Aircraft Purchase, Sale & Leasing <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/manpower-support'}> Manpower Support <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/camo-solutions'}> Camo Solutions <i className="far fa-long-arrow-right" /> </Link>   </li>
             <li> <Link to={'/ground-handling-services'}> Ground Handling Services <i className="far fa-long-arrow-right" /> </Link>   </li>
                        </ul>
                        {/* single categoris End */}
                    
                     
                   
                     
                    </div>
                </div> 
    
    </>
  )
}

export default AviationSidebar