import React from 'react'
import HeaderOne from "../components/header/HeaderOne";

import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const CamoSolutions = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Camo Solutions' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Camo Solutions" breadcrumbs={breadcrumbs} />


{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/camo-solutions.jpg" alt="AOG Service & Support" />
                    </div>
                    <h4 className="title"> CAMO Solution </h4>
                   <p><b>  "Expert Continuing Airworthiness Management for Your Aircraft". </b> Our CAMO team plays a critical role in maintaining the airworthiness of aircraft by ensuring compliance with manufacturer and regulatory requirements. 
                   Keeping the documentation aligned with the technical status of the aircraft is indeed a complex and vital task, as it ensures safety, regulatory compliance, and operational efficiency. 
                    Our team will prepare, peruse and complete the CAMO formalities on your behalf with regulatory requirement of FAA, EASA, DGCA or etc.  </p>
                    <p> At P.A.S. we take this responsibility seriously, offering end-to-end solutions that keep your fleet in the sky. 
                    Our CAMO services go beyond compliance – we optimize your operations for efficiency and reliability.  </p>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>



<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1 industries">
                <h5> Our CAMO Services Include:   </h5>
                    <ul>
<li><b> Maintenance Program Development: </b>  Crafting bespoke plans to meet manufacturer and regulatory standards.  </li>
<li> <b> Airworthiness Reviews: </b> Conducting ARC (Airworthiness Review Certificate) assessments for compliance. </li>
<li> <b> Technical Support: </b> Managing Airworthiness Directives (ADs) and Service Bulletins (SBs). </li>
<li><b> Records Oversight: </b> Ensuring all logs and documentation are audit-ready. </li>
 </ul>  
                </div>  
            </div> 

        </div>
    </div>
</div>
{/* End service details area */}


<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default CamoSolutions