import React, { useState, useEffect } from 'react';
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const AboutUs = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'About Us' }
    ];
  return (
   <>   
   
   <div className=''>

<HeaderOne />
<Breadcrumb title="About Us" breadcrumbs={breadcrumbs} />
{/* rts about us section start */}
<div className="rts-about-area rts-section-gap">
    <div className="container">
        <div className="row g-5 align-items-center">

<div className='col-lg-12 col-md-12 col-12'>  
</div>

            <div className="col-lg-12 col-md-12 col-12">
                <div className="about-progress-inner">
                    <div className="title-area">
                        <h2 className="title"> About us </h2>
                    </div>
                    {/* inner start */}
                    <div className="inner">
                        <p className='disc'>  Welcome to Planetronix Consulting Private Limited – Your Trusted Partner for Supply Chain Solution. Planetronix has been a shining example of innovation and reliability for over two decades. Established in 2001, Planetronix has evolved into a dependable ally across a wide range of critical sectors. We are providing service and support to various sectors such as Military and Defense, Aerospace, Government and Public Sector Units, Indian Railways, and Healthcare.</p>
                        {/* <p className="disc">
                        Greetings, Welcome to our website! Today, I'm excited to share with you the story of Planetronix Consulting Private Limited, a company that has been a shining example of innovation and reliability for over two decades. Established in 2001, Planetronix has evolved into a dependable ally across a wide range of critical sectors. We're discussing sectors such as Military and Defence, Aerospace, Government and Public Sector Units, Indian Railways, and Healthcare. This extensive coverage highlights their versatility and expertise.
                        </p> */}
                        <p className="disc"> Planetronix is a homegrown manufacturer of bespoke, rugged systems, support for aviation products and service. It also serve as a partner for numerous international Original Equipment Manufacturers (OEMs) for sales, support, and spare parts management in India and sub continent.  </p>
                        <p className="disc"> At Planetronix, we take pride in being an ISO-certified organization, committed to delivering world-class products, services, and solutions. Our certification underscores our dedication to quality, reliability, and continuous improvement, ensuring that we meet and exceed the highest industry standards. 
                        In sectors like defence and aerospace, it's imperative to have a partner who consistently excels. Our product portfolio and services are crafted to address the specific requirements of each sector we operate in. For the military and defence industry, we offer state-of-the-art technology and robust solutions that boost operational efficiency and security. In the aerospace sector, we focus is on quality , precision and dependability. For government and public sector entities, we ensures that solutions are both effective and sustainable. Let's not overlook our contribution to the Indian Railways, where we play a crucial role in the supply and maintenance of top-tier equipment, facilitating safe and efficient transportation – the cornerstone of our nation's connectivity. In the healthcare sector, Planetronix introduces innovative solutions that improve patient care and streamline operations. 
                        However, what truly distinguishes Planetronix is our relentless commitment to customer satisfaction. We engage closely with our clients to understand their unique needs and challenges, delivering customized solutions that deliver tangible results.   </p>
                        <p className="disc">  So, whether you're in the defence sector seeking dependable defence solutions, or in the healthcare industry aiming to enhance patient care, Planetronix Consulting Private Limited is your go-to partner. With over two decades of experience and a steadfast dedication to quality, they are a partner you can rely on. Thank you for joining us today to learn more about Planetronix. Remember, in an era where reliability and innovation are paramount, Planetronix remains a leader. Until next time, stay informed and stay ahead.    </p>
                    
                    </div>
                    {/* end */}
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts about us section end */}




{/* customers feed back area start */}
<div className="rts-customer-feedback-area rts-section-gap bg-customer-feedback">
    <div className="container">
        <div className="row">
            <div className="rts-title-area feedback team text-center">
                <p className="pre-title">Feedbacks</p>
                <h2 className="title">Customer Feedbacks</h2>
            </div>
        </div>
        <div className="row g-5 mt--20">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="testimopnial-wrapper-two">
                    <div className="test-header">
                        <div className="thumbnail">
                            <img src="assets/images/testimonials/02.png" alt="" />
                        </div>
                        <div className="name-desig">
                            <h5 className="title">David Smith</h5>
                            <span className="designation">Business Expert</span>
                        </div>
                    </div>
                    <div className="test-body">
                        <p className="disc">
                            “Parallel task user friendly convergence through supply are
                            chains type siflify reliable meta provide service visionary
                            sources unleash tactical thinking via granular intellectual
                            capital architect dynamic information value online business
                            solution services”
                        </p>
                    </div>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="testimopnial-wrapper-two">
                    <div className="test-header">
                        <div className="thumbnail">
                            <img src="assets/images/testimonials/03.png" alt="" />
                        </div>
                        <div className="name-desig">
                            <h5 className="title">David Smith</h5>
                            <span className="designation">Business Expert</span>
                        </div>
                    </div>
                    <div className="test-body">
                        <p className="disc">
                            “Parallel task user friendly convergence through supply are
                            chains type siflify reliable meta provide service visionary
                            sources unleash tactical thinking via granular intellectual
                            capital architect dynamic information value online business
                            solution services”
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
{/* customers feed back area end */}

<CallToActionThree />
<PrimaryFooter />

</div>

   
   </>
  )
}

export default AboutUs