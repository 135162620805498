import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Navigation, Scrollbar, A11y, EffectFade, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

const HomeProductSlider = () => {
  return (
   <>
        <div style={{backgroundColor:'#f2f1f6',padding:'50px'}}>
            {/* rts-service area start */}
            <div className="rts-service-area mt-5" >
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center title-service-three">
                                
                                <h2 className="title"> Our Products </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row g-5 mt--20">
                        <div className="col-12">
                            <div className="swiper ">
                                <Swiper
                                    // install Swiper modules
                                    modules={[Navigation, EffectFade, Scrollbar, A11y, Autoplay]}
                                    className="mySwiperh2_Service"
                                    speed={300}
                                    slidesPerView={3}
                                    spaceBetween={30}
                                    loop={true}
                                    autoplay={true}
                                
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                    breakpoints={{
                                        1200: { slidesPerView: 3 },
                                        900: { slidesPerView: 3 },
                                        768: { slidesPerView: 2 },
                                        580: { slidesPerView: 2 },
                                        450: { slidesPerView: 1 },
                                        0: { slidesPerView: 1 },
                                    }}
                                >


<SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/aviation-products'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/aviation-products.jpg"
                                                        alt="Rugged Display"
                                                    />
                                                </Link>
                                                <div className="content">
                                                   
                                                    <h5 className="title"> Aviation Products  </h5>
                                                   
                                                </div>
                                                <Link to={'/aviation-products'} className="over_link" />
                                            </div>
                                            <Link
                                                href="service-details.html"
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>





                                    <SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/rugged-displays'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/rugged-display.jpg"
                                                        alt="Rugged Display"
                                                    />
                                                </Link>
                                                <div className="content">
                                                   
                                                    <h5 className="title"> Rugged Display </h5>
                                                   
                                                </div>
                                                <Link to={'/rugged-displays'} className="over_link" />
                                            </div>
                                            <Link
                                                href="service-details.html"
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>




                                    <SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/service-details'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/rugged-kvm-display.jpg"
                                                        alt="Business-service"
                                                    />
                                                </Link>
                                                <div className="content">
                                                    <h5 className="title"> Rugged KVM Displays </h5>
                                                </div>
                                                <Link to={'/rugged-kvm-displays'} className="over_link" />
                                            </div>
                                            <Link
                                                href="service-details.html"
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/service-details'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/rugged-panel-pc.jpg"
                                                        alt="Rugged Panel PCs"
                                                    />
                                                </Link>
                                                <div className="content">
                                                   
                                                    <h5 className="title"> Rugged Panel PCs </h5>
                                                 
                                                </div>
                                                <Link to={'/rugged-panel-pcs'} className="over_link" />
                                            </div>
                                            <Link
                                                href="service-details.html"
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/service-details'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/rugged-workstations.jpg"
                                                        alt="Business-service"
                                                    />
                                                </Link>
                                                <div className="content">
                                                   
                                <h5 className="title"> Rugged Workstations </h5>
                                                  
                                                </div>
                                                <Link to={'/rugged-workstations'} className="over_link" />
                                            </div>
                                            <Link
                                                href="service-details.html"
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/service-details'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/rugged-servers.jpg"
                                                        alt="Business-service"
                                                    />
                                                </Link>
                                                <div className="content">
                                                 
                                                    <h5 className="title"> Rugged Servers </h5>
                                                   
                                                </div>
                                                <Link to={'/rugged-servers'} className="over_link" />
                                            </div>
                                            <Link
                                                to={'/rugged-servers'}
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <div className="service-one-inner-four">
                                            <div className="big-thumbnail-area">
                                                <Link to={'/service-details'} className="thumbnail">
                                                    <img
                                                        src="assets/images/service/ethernet-switches.jpg"
                                                        alt="Business-service"
                                                    />
                                                </Link>
                                                <div className="content">
                                                   
                                                    <h5 className="title"> Rugged Ethernet Switches </h5>
                                                   
                                                </div>
                                                <Link to={'/rugged-ethernet-switches'} className="over_link" />
                                            </div>
                                            <Link
                                                to={'/rugged-ethernet-switches'}
                                                className="rts-btn btn-primary-3"
                                            >
                                                {" "}
                                                Read More
                                                <i className="fal fa-arrow-right" />
                                            </Link>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                    <div className="row mt--80">
                        <div className="col-12">
                            <div className="pagination-arrow navigation-center-bottom service text-center position-relative">
                                <div className="swiper-button-next" />
                                <div className="swiper-pagination" />
                                <div className="swiper-button-prev" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* rts-service area end */}
        </div>
   
   </>
  )
}

export default HomeProductSlider