import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const AogSupportService = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'AOG Service & Support' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title=" AOG Service & Support" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/aog-support.jpg" alt="AOG Service & Support" />
                    </div>
                    <h4 className="title"> AOG Service & Support </h4>
                   <p> 24/7 AOG Support – Minimizing Downtime, Maximizing Efficiency; When your aircraft is grounded, every minute counts. At P.A.S., we provide 24/7 AOG support to ensure your aircraft is back in the air as quickly as possible. 
                    With a global network of experts, certified parts, and dedicated logistics, we deliver fast, reliable, and cost-effective solutions to minimize downtime and keep your operations running smoothly. </p>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

<div className='col-xl-12 col-lg-12 col-md-12 col-12 mt-5'> 
<h4 className='text-center pt-5'> Comprehensive AOG Support Solutions </h4>
<p className='text-center pb-5'> We offer a full range of AOG services to address your urgent needs, including: </p>
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> AOG Parts Supply </h5>
       <ul>
        <li> Immediate access to a vast inventory of certified aircraft parts </li>
        <li> Sourcing of hard-to-find and obsolete components </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Technical Support </h5>
       <ul>
        <li> On-site troubleshooting and repairs by certified technicians </li>
        <li> Remote technical assistance for quick problem resolution </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Logistics and Transportation </h5>
       <ul>
        <li> Expedited shipping of parts and equipment worldwide </li>
        <li> Coordination of on-site personnel and tools </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Maintenance and Repair </h5>
       <ul>
        <li> Rapid response for unscheduled maintenance </li>
        <li> Comprehensive repair services to restore airworthiness </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Consulting and Planning </h5>
       <ul>
        <li> Proactive AOG prevention strategies </li>
        <li> Customized support plans for fleet operators </li>
       </ul>
       </div> 
   </div>


        </div>
    </div>
</div>
{/* End service details area */}



<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default AogSupportService