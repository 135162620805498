import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const ComponentRepair = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Components Repair & Rental Services' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Components Repair & Rental Services" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/parts-components.jpg" alt="Components Repair & Rental Services" />
                    </div>
                    <h4 className="title"> Components Repair & Rental Services </h4>
                    <p> Elevate Your Aviation Operations with Premier Repair and Rental Service. We are offering sleek, high-tech repair shop with aviation components in focus. </p>
                   </div>  
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className='description'>  
                   <h5> Aviation Components Repair Services: </h5>
                    <ul>
<li> <b>  Specialization: </b> Focused on facilitating repairing a wide range of aviation components like engines, avionics, and hydraulics.  </li>
<li> <b>  Quality Assurance: </b> The Repair Station employs strict testing protocols to ensure components meet or exceed OEM standards post-repair. </li>
<li> <b> Efficiency: </b>  Quick turnaround times to minimize aircraft downtime, often using advanced diagnostic tools. </li>
<li> <b>  Certification: </b> Operations adhere to aviation regulatory bodies like FAA or EASA for compliance and safety. </li>

                    </ul>  
                </div>  
            </div>   


            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
            <div className='description'> 
                    <h5> Aviation Components Rental Services: </h5> 
                    <ul>
                  <li> <b>  Range: </b> Offers various components for rent, including hard-to-find or obsolete parts for various aircraft. </li>    
                  <li><b>  Cost-Effective: </b> Helps reduce the capital investment in purchasing less frequently used or backup components. </li>     
                  <li><b>  Flexibility: </b> Short-term or long-term rental options tailored to operational needs, with options for emergency rentals. </li>   
                  <li> <b>  Maintenance Included: </b> Ensured that rented components are maintained to high standards, with repair services available if needed during the rental period. </li>   

                    </ul>
                </div>  
            </div> 
        </div>


<div className='row my-5'>
<div className='col-xl-6 col-lg-6 col-md-6 col-12 industries'> 
<h4 className='pt-5'> Industries We Serve </h4>
<p> Serving the Global Aviation Industry; Our products and services cater to a wide range of aviation sectors, including: </p>
<ul> 
<li> General Aviation, Private and Business Aviation </li>
<li> Commercial Airlines </li>
<li> Military and Défense </li>
<li> Maintenance, Repair, and Overhaul (MRO) Facilities </li>
<li> Aircraft Manufacturers </li>
<li> Research & Development Agency </li>
</ul>
<p> No matter your industry or aircraft type, we have the components repair and rental service to support your operations. </p>
  </div>

   <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
   <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />
   </div>
</div>



    </div>
</div>
{/* End service details area */}



<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default ComponentRepair