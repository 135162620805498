import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";

import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import ProductsSidebar from './ProductsSidebar';

const RuggedPanelPcs = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged Panel PCs' }
    ];
  return (
   <>  
   
 
   <div className=''>
<HeaderOne />
<Breadcrumb title="Rugged Panel PCs" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                <div className="thumbnail">
                <img src='../../assets/images/products/rugged-panel-pcs.png' alt='Rugged Displays'  />
                    </div>

                    <h4 className="title"> Rugged Panel PCs  </h4> 
                    <p className="disc"> Planetronix offers a wide range of Rugged Panel PCs with performance and expansion capability not found in notebook PCs or Laptop computers. </p>
                    <p className="disc"> Our Rugged Panel PCs are highly configurable and designed for people who require the power and expansion capability of a desktop PC in a portable range.  </p>
                    <p className="disc"> Our Rugged Panel PCs are fully custom configured and have many options such as expansion slots, high end processor power, massive fixed and removable storage with integrated LCD Displays and designed for mission-critical applications that demand both high performance and extreme reliability in the harsh environmental conditions. </p>
                </div>  
<div className='row'> 

    <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='productdesc'> 
        <h5 className='title mb-0'> 12 Series Rugged Panel PCs </h5>
        <p className="disc mb-0">  MIL-STD 461E/F | MIL-STD 810G | JSS55555 </p>
        <img src='../../assets/images/products/12-series-rugged-panelpc.png' alt='12 Series Rugged Panel PCs' />
        <table className='table border table-bordered'>
  <tbody>
    <tr>
      <th> Screen Size	</th>
      <td> 12″ TFT Display      </td> 
    </tr>
    <tr>
      <th> CPU	</th>
      <td> Intel Atom Series </td> 
    </tr>
    <tr>
      <th> Memory	</th>
      <td> 4GB DDR3L 1600MHz </td> 
    </tr>
    <tr>
      <th> Storage	</th>
      <td> 1TB SSD </td> 
    </tr>
    <tr>
      <th> Mounting / Size	</th>
      <td> Console Mount </td> 
    </tr>
  </tbody>
</table>
</div>
         </div>


         <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <div className='productdesc'> 
        <h5 className='title mb-0'> 15 Series Rugged Panel PCs </h5>
        <p className="disc mb-0"> MIL-STD 461E/F | MIL-STD 810G | JSS55555 </p>
        <img src='../../assets/images/products/15-series-rugged-panel-pc.png' alt='12 Series Rugged Panel PCs' />
        <table className='table border table-bordered'>
  <tbody>
    <tr>
      <th> Screen Size	</th>
      <td> 12″ TFT Display </td> 
    </tr>
    <tr>
      <th> CPU 	</th>
      <td> Intel Atom Series     </td> 
    </tr>
    <tr>
      <th> Memory	</th>
      <td> 4GB DDR3L 1600MHz </td> 
    </tr>
    <tr>
      <th> Storage	</th>
      <td> 1TB SSD     </td> 
    </tr>
    <tr>
      <th> Mounting / Size	</th>
      <td> Console Mount </td> 
    </tr>
  </tbody>
</table>
</div>
 </div>

     </div>
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
             
<ProductsSidebar />


                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}







<CallToActionThree />

<PrimaryFooter />
</div>
   
   </>
  )
}

export default RuggedPanelPcs