import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const SparePartsRetableSales = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Spare Parts & Rotables Sales' }
    ];
  return (
    <>   
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Spare Parts & Rotables Sales" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/parts-components.jpg" alt="Spare Parts & Retables Sales" />
                    </div>

                    <h4 className="title"> Spare Parts, Rotables Sales and Consumables  </h4>
                    <p className='mb-0'> <b> “Your Trusted Source for Premium Aircraft Spare Parts, Rotables, Expendable and Consumables;” at P.A.S. </b>  is a leading provider o for commercial, private, and military aviation. 
                      With decades of experience and a global network of suppliers, we offer a comprehensive inventory of new, overhauled, and certified parts to meet your operational needs.   </p>
                 <p> With a focus on customer satisfaction, we offer competitive pricing, fast delivery, and personalized service to meet the unique needs of our clients. </p>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

<div className='col-xl-6 col-lg-6 col-md-6 col-12 industries'> 
<h4> Why Choose Us? </h4>
<ul> 
<li> Extensive inventory of new, overhauled, and certified parts  </li>
<li> Global sourcing network for hard-to-find components </li>
<li> Compliance with FAA, EASA, and other regulatory standards </li>
<li> Dedicated customer support and technical expertise </li>
<li> Fast and reliable shipping worldwide </li>
</ul>
  </div>

   <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
   <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />

   </div>



   <div className='col-xl-12 col-lg-12 col-md-12 col-12'> 
<h4 className='text-center pt-5'> Our Products </h4>
<p className='text-center pb-5'> Comprehensive Range of Aircraft Parts and Components; We supply a wide variety of aircraft parts and components for all major aircraft models, including: </p>
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Airframe Components </h5>
       <ul>
        <li> Wings, fuselage, and structural parts </li>
        <li> Landing gear and hydraulic systems </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
   <div className='description'>
     <h5> Engine Parts </h5>
       <ul>
        <li> Turbine blades, compressors, and fuel systems </li>
        <li> Engine mounts and exhaust components </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
   <div className='description'>
     <h5> Avionics and Electrical Systems </h5>
       <ul>
        <li> Navigation and communication systems</li>
        <li> Wiring harnesses and connectors </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
   <div className='description'>
     <h5> Interior Components</h5>
       <ul>
        <li> Seats, galleys, and lavatories </li>
        <li> Cabin lighting and entertainment systems </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
   <div className='description'>
     <h5> Consumables and expendables </h5>
       <ul>
        <li> Fasteners, seals, and gaskets </li>
        <li> Lubricants and fluids </li>
       </ul>
       </div> 
   </div>
   </div>
   <div className="row">
   <div className='col-xl-6 col-lg-6 col-md-6 col-12 industries'> 
   <h4> Industries We Serve </h4>
   <p> Serving the Global Aviation Industry; Our products and services cater to a wide range of aviation sectors, including: </p>
<ul>  
<li> General Aviation, Private and Business Aviation </li>
<li> Commercial Airlines </li>
<li> Military and Défense </li>
<li> Maintenance, Repair, and Overhaul (MRO) Facilities </li>
<li> Aircraft Manufacturers </li>
<li> Research & Development Agency  </li>

</ul>
<p> No matter your industry or aircraft type, we have the parts and expertise to support your operations. </p>

   </div>
   <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
   <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />
   </div>



   <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
     <img src="assets/images/quality-assurance.jpg" alt="Supply Chain Management" />
         </div>

   <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
    <h4> Quality Assurance </h4>
    <p className='mb-0'><b> Quality You Can Trust; we prioritize quality and safety above all else.  </b> 
        All our parts and components are sourced from certified suppliers and undergo rigorous inspection to ensure compliance with international aviation standards, including FAA, EASA, and ISO certifications.</p>
        <p> Our commitment to quality ensures that your aircraft remains safe, reliable, and airworthy.  </p>
     </div>

     <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
      <h4> Sourcing and Logistics </h4>
      <p> With a vast network of suppliers and partners worldwide, we specialize in sourcing hard-to-find and obsolete parts. 
        Our efficient logistics team ensures timely delivery, no matter where you are located. </p>  
        <ul> 
<h6> Key Features: </h6>
<li> Global sourcing capabilities </li>
<li> Expedited shipping options </li>
<li> Real-time inventory tracking </li>

        </ul>
         </div>

         <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
     <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />
         </div>

    




        </div>
    </div>
</div>






<CallToActionThree />

<PrimaryFooter />
</div>
  
    </>
  )
}

export default SparePartsRetableSales
