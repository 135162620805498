import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const SupplyChainManagement = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Supply Chain Managment' }
    ];
  return (
    <>   
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Supply Chain Management" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />
                    </div>
                    <h4 className="title"> Supply Chain Management </h4>
<p> At Planetronix , we specialize in delivering end-to-end aviation supply chain solutions, ensuring efficiency, cost-effectiveness, and compliance with industry standards. 
  Our streamlined approach minimizes downtime, optimizes logistics, and enhances operational reliability for airlines, MROs, and aviation businesses. </p>
                   </div>  
            </div>

            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

</div>

<div className='row'> 
<h4 className="title text-center py-5"> Our Supply Chain Solutions </h4>
<div className='col-xl-4 col-lg-4 col-md-4 col-12'> 
        <div className='description'> 
        <h5> Aircraft Parts Procurement & Sourcing </h5>
     <ul>
<li> Direct sourcing from certified manufacturers and OEMs. </li>
<li> FAA, EASA, and industry-compliant components.  </li> 
     </ul>
     </div>
         </div>


         <div className='col-xl-4 col-lg-4 col-md-4 col-12'> 
        <div className='description'> 
        <h5> Inventory & Warehouse Management </h5>
     <ul>
<li> Real-time inventory tracking for optimal stock levels.  </li>
<li> Predictive analytics to prevent shortages and delays. </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-4 col-lg-4 col-md-4 col-12'> 
        <div className='description'> 
        <h5> Logistics & Distribution </h5>
     <ul>
<li> Global shipping solutions with just-in-time (JIT) delivery. </li>
<li> AOG (Aircraft on Ground) support for urgent part replacements. </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-4 col-lg-4 col-md-4 col-12'> 
        <div className='description'> 
        <h5> MRO (Maintenance, Repair & Overhaul) Support </h5>
     <ul>
<li> Reliable access to critical maintenance components. </li>
<li> Partnerships with top-tier MRO providers for faster turnaround times. </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-4 col-lg-4 col-md-4 col-12'> 
        <div className='description'> 
        <h5> Technology-Driven Supply Chain </h5>
     <ul>
<li> AI & IoT integration for real-time tracking. </li>
<li> Blockchain solutions for secure and transparent transactions. </li>
     </ul>
     </div>
         </div>


         <div className='col-xl-4 col-lg-4 col-md-4 col-12'> 
        <div className='description'> 
        <h5> Sustainability & Compliance </h5>
     <ul>
<li> Eco-friendly sourcing and waste reduction strategies. </li>
<li> Adherence to international aviation safety and environmental regulations. </li>
     </ul>
     </div>
         </div>
     </div>


<div className='row'>

<div className='col-xl-6 col-lg-6 col-md-6 col-12 industries'> 
<h4 className='pt-5'> Why Choose Planetronix Aviation? </h4>
<ul> 
<li> Reduced Downtime & AOG Risks – Fast, efficient part sourcing and delivery.  </li>
<li> Cost Optimization – Data-driven inventory and procurement management. </li>
<li> Regulatory Compliance – Certified aviation components and logistics. </li>
<li> Global Reach, Local Expertise – Worldwide network with tailored regional support. </li>
</ul>
  </div>

   <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
   <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />

   </div>


</div>




    </div>
</div>



<CallToActionThree />

<PrimaryFooter />
</div>
  
    </>
  )
}

export default SupplyChainManagement