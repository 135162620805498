import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const EngineeringCertification = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Engineering & Certification/STCs' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Engineering & Certification/STCs" breadcrumbs={breadcrumbs} />



{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                    <img src="assets/images/engineering-certification.jpg" alt="Engineering & Certification/STCs" />
                    </div>
                    <h4 className="title"> Engineering & Certification/STCs </h4>
                   <p> <b> Streamlining Compliance and Enhancing Aircraft Performance with STCs and Engineering Solutions. </b> We can facilitate this service through our trusted service provider of engineering and certification services for the aviation industry. They are specializing in developing Supplemental Type Certificates (STCs) and delivering engineering solutions that ensure compliance, enhance performance, and bring your aviation projects to life. 
                    Whether you’re modifying an aircraft or introducing new technology, our partnered team is here to guide you every step of the way. </p>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

<div className='col-xl-12 col-lg-12 col-md-12 col-12 my-5'> 
<h4 className='text-center pt-5'> Service Offered </h4>
<p className='text-center pb-5'> A full range of engineering and certification services to support your aviation projects: </p>
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Supplemental Type Certificates (STCs) </h5>
       <ul>
        <li> Development and approval of STCs for aircraft modifications </li>
        <li> Compliance with FAA, EASA, and other regulatory requirements </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Engineering Design and Analysis </h5>
       <ul>
        <li> Structural, mechanical, and electrical engineering services </li>
        <li> Stress analysis, weight and balance calculations, and system integration </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Regulatory Compliance Support </h5>
       <ul>
        <li> Guidance on certification processes and documentation </li>
        <li> Liaison with regulatory authorities for approvals </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Aircraft Modifications and Upgrades </h5>
       <ul>
        <li> Avionics upgrades, interior refurbishments, and performance enhancements </li>
        <li> Custom solutions for unique operational needs </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Testing and Validation </h5>
       <ul>
        <li> Ground and flight testing for modified aircraft </li>
        <li> Data collection and analysis to ensure compliance </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Project Management </h5>
       <ul>
        <li> End-to-end management of engineering and certification projects </li>
        <li> Timely delivery and cost-effective solutions </li>
       </ul>
       </div> 
   </div>


        </div>
    </div>
</div>
{/* End service details area */}




<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default EngineeringCertification





