import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import { Link } from 'react-router-dom';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';

const GovernmentPsu = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Government & PSU' }
    ];
  return (
   <>  
 <div className=''>

<HeaderOne />
<Breadcrumb title="Government and PSU" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
          
        <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 col-12 mb_md--20 mb_sm--20">
                            <h4 className="title"> Government and PSU </h4>
<p className="disc">  Economics comes at play when an establishment like the Government or the PSUs have to make wide-scale decisions. 
    They have to take into consideration things like the scarcity of resources while offering benefits for the urban community. 
    They also have to have a look into issues like climate change while maintaining a strong foot in the global economy. 
    Thus, such agencies aim at bringing about innovation in their decision-making systems. Possessing knowledge about this change, PCPL aims to cater to the wide array of needs of the Government and Public Sector Undertakings. 
    We work to increase the efficiency of governance practices while providing innovation by means of technology. 
                            </p>
                           
                        </div>


            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
                {/* single wizered start */}





                <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Industries </h5>
                    </div>
                    <div className="wized-body">
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/military-defence'}>
                                    Military & Defence <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/aerospace'}>
                                    Aerospace <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}
                        {/* single categoris */}
                        <ul className="single-categories">
                            <li>
                                <Link to={'/government-psu'}>
                                    Government & PSU <i className="far fa-long-arrow-right" />
                                </Link>
                            </li>
                        </ul>
                        {/* single categoris End */}         
                    </div>
                </div> 





            </div>
        </div>

<div className='row my-5'>
    <div className='col-lg-12 col-md-12 col-12'>
        
    <div className="rts-single-wized contact service">
                   
                    <div className="wized-body">
                        <h5 className="title">Need Help? We Are Here To Help You</h5>
                        <Link className="rts-btn btn-primary" to={'#'}>
                            Contact Us
                        </Link>
                    </div>
                </div>
        
        
        
          </div>
    
      </div>



    </div>
</div>
{/* End service details area */}



<CallToActionThree />

<PrimaryFooter />

</div>

 
   </>
  )
}

export default GovernmentPsu