import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const CharterService = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Charter Service' }
    ];
  return (
   <>
   
   <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Charter Service" breadcrumbs={breadcrumbs} />


{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1 industries">
                    <div className="thumbnail">
                    <img src="assets/images/charter-service.jpg" alt="Avionics Installation" />
                    </div>
                    <h4 className="title"> Charter Service </h4>
                   <p> As an Aviation charter service providers, we play a critical role in offering bespoke travel solutions, 
                    emphasizing flexibility, luxury, and efficiency while navigating the complexities of aviation operations and 
                    client expectations. </p>
                    <p className='mb-0'> Our Key services & features are outlines as</p>
                    <ul className='mt-0'>
                        <li> <b> Services: </b> Offers private, on-demand air travel where clients can charter entire aircraft for tailored flight itineraries.  </li>
                        <li> <b> Aircraft Fleet: </b> These include jets, turboprops, and helicopters, varying from light to heavy categories to suit different needs.  </li>
                    </ul>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

<div className='col-xl-12 col-lg-12 col-md-12 col-12 mt-5'> 
<h4 className='text-center pt-5'> Our Services </h4>
<p className='text-center pb-5'> We are offering aircraft charter various industries where efficiency, convenience, and flexibility in travel are essential. 
    Here are some key industries we support </p>
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Business & Corporate Travel </h5>
       <ul>
        <li> Executives, VIPs, and high-net-worth individuals needing private jet travel. </li>
        <li> Time-sensitive meetings, mergers, and acquisitions requiring fast mobility. </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Tourism & Luxury Travel </h5>
       <ul>
        <li> High-end travelers seeking private jet experiences. </li>
        <li> Exclusive travel agencies offering luxury vacation packages. </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Oil & Gas Industry </h5>
       <ul>
        <li> Transporting engineers, executives, and workers to remote drilling sites. </li>
        <li> Emergency response and crew rotations. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Healthcare & Medical Evacuation (Medevac) </h5>
       <ul>
        <li> Emergency air ambulance services for critical patients. </li>
        <li> Organ transplant transportation requiring urgent delivery. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Government & Defense </h5>
       <ul>
        <li> Transporting officials, military personnel, and diplomats. </li>
        <li> Emergency and disaster relief operations. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Sports & Entertainment </h5>
       <ul>
        <li> Charter services for professional athletes, teams, and coaches. </li>
        <li> VIP travel for celebrities, musicians, and film crews. </li>
       </ul>
       </div> 
   </div>



   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Freight & Cargo Transport </h5>
       <ul>
        <li> Urgent delivery of high-value goods, spare parts, and critical shipments. </li>
        <li> Transportation of time-sensitive cargo like perishables or pharmaceuticals. </li>
       </ul>
       </div> 
   </div>



   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Mining & Construction </h5>
       <ul>
        <li> Flying personnel to remote mining sites. </li>
        <li> Equipment and supply transportation to hard-to-reach areas. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Emergency & Disaster Relief </h5>
       <ul>
        <li> Rapid deployment of rescue teams and medical supplies. </li>
        <li> Humanitarian aid transport in disaster-stricken regions. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Agriculture & Livestock Transport </h5>
       <ul>
        <li> Transporting agricultural experts, seeds, and equipment to rural locations. </li>
        <li> Airlifting livestock or perishable farm products. </li>
       </ul>
       </div> 
   </div>


        </div>
    </div>
</div>
{/* End service details area */}


<div className='container'> 
    <div className='row'>     
    <div className="service-detials-step-2 ">
                    <h4 className="title text-center mb-25"> Our Benefits: </h4>
                    {/* stem-area start */}
                    <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">01</p>
                                <h6 className="title"> Customization </h6>
                                <p className="disc"> Flight routes, timings, and amenities can be adjusted to client specifications. </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">02</p>
                                <h6 className="title"> Efficiency  </h6>
                                <p className="disc"> Bypasses commercial airport hassles, offering direct point-to-point service. </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">03</p>
                                <h6 className="title"> Privacy & Security </h6>
                                <p className="disc"> Controlled environment for sensitive travel or high-profile individuals. </p>
                            </div>
                        </div>
                    </div>
                </div>
        
        
         </div>
    
     </div>


<CallToActionThree />

<PrimaryFooter />
</div>

   
   </>
  )
}

export default CharterService