import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';

const ManpowerSupport = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Manpower Support' }
    ];
  return (
    <>    
       
<div className=''>
<HeaderOne />
<AviationBreadcrumb title="Manpower Support" breadcrumbs={breadcrumbs} />

<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/manpower-support.jpg" alt="Supply Chain Management" />
                    </div>
                   </div>  
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <h4 className="title"> Manpower Support </h4>
                    <p className='desc'><b> “Skilled Aviation Professionals” At P.A.S. </b> we provide top-tier aviation professionals to support your operations, including pilots, engineers, technicians, and ground staff. 
                        With a focus on reliability, expertise, and efficiency, we ensure you have the skilled workforce needed to meet the demands of the aviation industry.  </p>
                </div>  
            </div>
        </div>
    </div>
</div>



<CallToActionThree />

<PrimaryFooter />
</div>    
   

    
    
    </>
  )
}

export default ManpowerSupport