import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const InsuranceServices = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Insurance Services' }
    ];
  return (
   <> 
   
   <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Insurance Services" breadcrumbs={breadcrumbs} />


{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                    <img src="assets/images/insurance-services.jpg" alt="Insurance Services" />
                    </div>
                    <h4 className="title"> Insurance Services </h4>
                   <p> At Planetronix , we offer specialized insurance solutions tailored for the aviation industry, ensuring protection against financial risks, accidents, and operational disruptions. 
                    Our insurance plans cover a wide range of aviation-related needs, providing peace of mind for operators, owners, and businesses. </p>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

<div className='col-xl-12 col-lg-12 col-md-12 col-12 my-5'> 
<h4 className='text-center pt-5'> Our Aviation Insurance Solutions </h4>
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Aircraft Hull & Liability Insurance </h5>
       <ul>
        <li> Coverage for aircraft damage due to accidents, natural disasters, or theft. </li>
        <li> Third-party liability protection for passengers, cargo, and ground operations. </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Pilot & Crew Insurance </h5>
       <ul>
        <li> Life and health coverage for pilots, flight crew, and ground staff. </li>
        <li> Loss of license protection for pilots due to medical disqualification. </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Cargo & Freight Insurance </h5>
       <ul>
        <li> Protection for high-value and time-sensitive shipments. </li>
        <li> Coverage for damages or losses during transport. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> MRO (Maintenance, Repair & Overhaul) Insurance </h5>
       <ul>
        <li> Liability coverage for MRO facilities handling aircraft maintenance. </li>
        <li> Protection against claims arising from service-related incidents. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5>  Airport & Ground Operations Insurance </h5>
       <ul>
        <li> Coverage for aviation service providers, fueling stations, and ground handlers. </li>
        <li> Liability protection for aircraft hangars, terminals, and aviation businesses. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Drone & UAV Insurance </h5>
       <ul>
        <li> Coverage for commercial drone operations. </li>
        <li> Protection against damage, liability, and operational risks. </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Travel & Charter Insurance </h5>
       <ul>
        <li> Passenger protection for chartered flights and private jet travelers. </li>
        <li> Trip cancellation and emergency medical coverage. </li>
       </ul>
       </div> 
   </div>


        </div>



        <div className='row'>

<div className='col-xl-6 col-lg-6 col-md-6 col-12 industries'> 
<h4 className='pt-5'> Why Choose Planetronix Aviation Insurance? </h4>
<ul> 
<li> <b> Industry-Specific Coverage – </b>  Tailored policies for all aviation sectors. </li>
<li> <b> Global Network – </b>  Coverage for international operations and businesses. </li>
<li> <b> Fast Claims Processing – </b>  Minimized downtime with efficient claim settlements. </li>
<li> <b> 24/7 Support – </b> Dedicated aviation insurance specialists available anytime. </li>
</ul>
<p> Protect your aviation assets and operations with customized insurance solutions from Planetronix Insurance Services  </p>
  </div>

   <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
   <img src="assets/images/supply-chain-management.jpg" alt="Supply Chain Management" />

   </div>


</div>



    </div>
</div>
{/* End service details area */}



<CallToActionThree />

<PrimaryFooter />
</div>  
   
   </>
  )
}

export default InsuranceServices