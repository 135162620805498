import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import { Link } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import IndustriesSidebar from './IndustriesSidebar';

const Aerospace = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Aviation' }
    ];
  return (
    <>  
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Aviation" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                        <img src="assets/images/products/aviation-support.jpg" alt="General Aviation Support" />
                    </div>
                    <h4 className="title"> Aerospace </h4>
                    <p className="disc">
                    Streamline your aviation supply chain with our innovative, end-to-end solutions designed to reduce downtime, enhance efficiency, and improve visibility across the entire value chain. 
                    From spare parts sourcing to predictive maintenance and regulatory compliance, we’ve got you covered.
                    </p>
                </div>  
            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
              

<IndustriesSidebar />



                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}



<div className="rts-service-details-area">
<div className='container'>
<h4 className="title text-center mb-25"> Solutions </h4>
<div className="row g-5 mt--30 mb--40">
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                                <div className="details">
                                    <h6 className="title mb-0"> Inventory Management </h6>
                                    <p className="disc"> Our advanced inventory management system ensures the availability of critical parts when you need them while minimizing excess stock and associated costs. 
                                        Features include real-time inventory tracking, automated reordering, and predictive analytics to forecast future needs.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                              
                                <div className="details">
                                <h6 className="title mb-0"> Sourcing and Procurement </h6>
                                    <p className="disc"> We simplify your procurement process by connecting you with a global network of certified suppliers. 
                                        Our platform ensures compliance with regulatory standards and offers transparency in pricing, lead times, and availability.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                              
                                <div className="details">
                                <h6 className="title mb-0"> Predictive Maintenance </h6>
                                    <p className="disc"> Reduce aircraft downtime with our predictive maintenance tools. 
                                    By analyzing data from various sensors and systems, we predict potential part failures before they occur, helping you avoid costly delays and disruptions.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                                <div className="details">
                                <h6 className="title mb-0"> Compliance and Documentation </h6>
                                    <p className="disc">
                                    Ensure your aviation operations stay compliant with local and international regulations. 
                                    Our platform manages documentation, certifications, and ensures traceability for every component in your supply chain.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>


                        <div className="col-lg-6">
                            {/* single service details card */}
                            <div className="service-details-card">
                                <div className="details">
                                <h6 className="title mb-0"> Logistics and Freight Management </h6>
                                    <p className="disc">
                                    Our logistics management solution handles everything from warehousing to international shipping, ensuring that your parts are delivered on time, anywhere in the world. 
                                    We manage relationships with multiple carriers to provide the best rates and delivery times.
                                    </p>
                                </div>
                            </div>
                            {/* single service details card End */}
                        </div>
                    </div>
      </div>
      </div>





<div className="rts-service-details-area">
<div className='container'>
<h4 className="title text-center mb-25"> Planetronix Aviation Services </h4>
<div className="row g-5 mt--30 mb--40">


<div className="col-lg-2 col-md-4 col-12">
 <div className='service_box'>
    <img src='assets/images/service/supply-chain-management.svg' alt='Warehouse' />
    <Link to={'/supply-chain-management'}> <h5> Supply Chain Management </h5></Link>
    </div> 
</div>

<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/aircraft-parts.svg' alt='Parts & Rotables Sales' />
    <Link to={'/spare-parts-retable-sales'}>   <h5> Parts & Rotables Sales </h5>  </Link>
    </div>
</div> 

<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/component-repair.svg' alt='Components Repair & Rental Services' />
    <Link to={'/component-repair-rental-services'}>   <h5> Components Repair & Rental Services </h5> </Link>
    </div>
</div>


<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/aog-support.svg' alt='AOG Service & Support' />
    <Link to={'/aog-support-service'}>   <h5> AOG Service & Support </h5> </Link>
    </div>
</div> 

<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/avionics-installation.svg' alt='Avionics Installation' />
    <Link to={'/avionics-installation'}>    <h5> Avionics Installation </h5>  </Link>
    </div>
</div> 

<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/engineering-certification.svg' alt='Engineering & Certification/STCs' />
    <Link to={'/engineering-and-certification'}>    <h5> Engineering & Certification/STCs </h5> </Link>
    </div>
</div> 


<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/insurance-service.svg' alt='Insurance Services' />
    <Link to={'/insurance-services'}>  <h5> Insurance Services </h5>  </Link>
    </div>
</div>


<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/charter-service.svg' alt='Charter Service' />
    <Link to={'/charter-service'}>   <h5> Charter Service </h5> </Link>
    </div>
</div>



<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/sale-lease.svg' alt='Sale & Leasing' />
    <Link to={'/aircraft-sale-purchase-lease'}>  <h5> Aircraft Purchase, Sale & Leasing </h5> </Link>
    </div>
</div>


<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/manpower-support.svg' alt='Manpower Support' />
    <Link to={'/manpower-support'}>  <h5> Manpower Support </h5> </Link>
    </div>
</div>

<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/camo-solutions.svg' alt='CAMO Solution' />
    <Link to={'/camo-solutions'}>  <h5> CAMO Solution </h5> </Link>
    </div>
</div>

<div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/ground-handling-services.svg' alt='Ground Handling services' />
    <Link to={'/ground-handling-services'}>  <h5> Ground Handling services </h5> </Link>
    </div>
</div>


{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/aircraft-purchase.svg' alt='Airframe Maintenance' />
    <Link to={'#'}>  <h5> Airframe Maintenance </h5> </Link>
    </div>
</div>  */}



{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/components-repairs.svg' alt='Components Repairs' />
    <h5> Components Repairs </h5>
    </div>
</div>  */}

{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/engine-apu.svg' alt='Engine & APU' />
    <h5> Engine & APU </h5>
    </div>
</div>  */}






{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/fbo-services.svg' alt='FBO Services' />
    <h5> FBO Services </h5>
    </div>
</div>  */}


{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/flight-services.svg' alt='Flight Services' />
    <h5> Flight Services </h5>
    </div>
</div>  */}

{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/interior-refurbishment.svg' alt='Interior Refurbishment' />
    <h5> Interior Refurbishment </h5>
    </div>
</div>  */}


{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/manufacturing-solutions.svg' alt='Manufacturing Solutions' />
    <h5> Manufacturing Solutions </h5>
    </div>
</div>  */}


{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/paint-refurbishment.svg' alt='Paint Refurbishment ' />
    <h5> Paint Refurbishment </h5>
    </div>
</div>  */}







{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/rapid-response.svg' alt='Rapid Response' />
    <h5> Rapid Response </h5>
    </div>
</div>  */}


{/* <div className="col-lg-2 col-md-4 col-12">
<div className='service_box'>
    <img src='assets/images/service/specialized-services.svg' alt='Specialized Services' />
    <h5> Specialized Services </h5>
    </div>
</div>  */}

</div>
      </div>
      </div>








<div className='container'> 
    <div className='row'>     
    <div className="service-detials-step-2 mt--40">
                    <h4 className="title text-center mb-25"> Industries We Serve </h4>
                    {/* stem-area start */}
                    <div className="row  mb--40 g-5 mb_md--20 mb_sm--20">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">01</p>
                                <h6 className="title"> Airlines </h6>
                                <p className="disc"> Tailored solutions to streamline your fleet maintenance and reduce operational costs.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">02</p>
                                <h6 className="title"> Maintenance, Repair & Overhaul (MRO)  </h6>
                                <p className="disc"> Efficient sourcing and inventory management to keep your MRO operations running smoothly.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-service-step text-center">
                                <p className="step">03</p>
                                <h6 className="title"> Original Equipment Manufacturers (OEMs) </h6>
                                <p className="disc"> Collaborate with us to manage complex global supply chains and ensure timely delivery of critical parts.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        
        
         </div>
    
     </div>





{/* rts faq section area */}
<div className="rts-faq-section rts-section-gap rts-faq-bg bg_image">
    <div className="container">
        <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="faq-two-inner">
                    <div className="title-area-faq">
                        <span className="sub">WHY CHOOSE US</span>
                        <h2 className="title">
                            We Are Experienced
                            <span className="sm-title">
                                Business <span>Solution</span>
                            </span>
                        </h2>
                    </div>
                    {/* faq accordion area */}
                    <div className="faq-accordion-area">
                       
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><span>01. </span>  Industry Expertise </Accordion.Header>
                                <Accordion.Body> Decades of experience in the aviation sector. </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header><span>02. </span>  Comprehensive Solutions </Accordion.Header>
                                <Accordion.Body> From procurement to maintenance and logistics, we offer a one-stop solution.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="2">
                                <Accordion.Header><span>03. </span> Global Network </Accordion.Header>
                                <Accordion.Body>
                                Access to certified suppliers and partners worldwide.
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="3">
                                <Accordion.Header><span>04. </span> Advanced Technology </Accordion.Header>
                                <Accordion.Body>
                                Leverage real-time data, automation, and predictive analytics.
                                </Accordion.Body>
                            </Accordion.Item>


                            <Accordion.Item eventKey="4">
                                <Accordion.Header><span>05. </span> Regulatory Compliance </Accordion.Header>
                                <Accordion.Body>
                                Ensure you meet all local and international standards.
                                </Accordion.Body>
                            </Accordion.Item>



                        </Accordion>
                    </div>
                    {/* faq accordion area end */}
                </div>
            </div>
            <div className="col-lg-6">
                <div className="thumbnail-faq-four">
                    <img src="assets/images/faq/02.png" alt="" />
                </div>
            </div>
        </div>
    </div>
</div>
{/* rts faq section area End */}


<CallToActionThree />

<PrimaryFooter />
</div>

 </>
  )
}

export default Aerospace