
import React from 'react'
import HeaderOne from "../components/header/HeaderOne";

import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import AviationBreadcrumb from '../inner/AviationBreadcrumb';
import AviationSidebar from './AviationSidebar';

const AvionicsInstallation = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Avionics Installation' }
    ];
  return (
    <>
    
    <div className=''>
<HeaderOne />
<AviationBreadcrumb title="Avionics Installation" breadcrumbs={breadcrumbs} />


{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <div className="service-detials-step-1">
                    <div className="thumbnail">
                    <img src="assets/images/avionics-installation.jpg" alt="Avionics Installation" />
                    </div>
                    <h4 className="title"> Avionics Installation </h4>
                   <p> “Expert Avionics Installation Services for Safer, Smarter Aircraft”. We at P.A.S. can provide avionics installation services for private, commercial, and military aviation. 
                    The certified technicians who are specialize in the installation, integration, and upgrade of advanced avionics systems, ensure your aircraft is equipped with the latest technology for enhanced safety, efficiency, and performance. </p>
                   </div>  
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <AviationSidebar />
</div>

<div className='col-xl-12 col-lg-12 col-md-12 col-12 mt-5'> 
<h4 className='text-center pt-5'> Our Services </h4>
<p className='text-center pb-5'> We offer a full range of avionics installation services to enhance your aircraft’s capabilities: </p>
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Avionics System Installation </h5>
       <ul>
        <li> Flight management systems (FMS) </li>
        <li> Navigation and communication systems </li>
        <li> Weather radar and surveillance systems </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> System Integration and Upgrades </h5>
       <ul>
        <li> Integration of new avionics with existing systems </li>
        <li> Retrofit solutions for older aircraft </li>
       </ul>
       </div> 
   </div>

   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Glass Cockpit Upgrades </h5>
       <ul>
        <li> Installation of advanced display systems </li>
        <li> Transition from analog to digital cockpits </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> ADS-B and Compliance Solutions </h5>
       <ul>
        <li> ADS-B Out and In installations </li>
        <li> Compliance with global mandates </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Cabin Entertainment and Connectivity </h5>
       <ul>
        <li> In-flight entertainment systems </li>
        <li> Wi-Fi and satellite communication systems </li>
       </ul>
       </div> 
   </div>


   <div className="col-lg-4 col-md-4 col-12">
    <div className='description'>
     <h5> Maintenance and Support </h5>
       <ul>
        <li> Post-installation testing and calibration </li>
        <li> Ongoing maintenance and troubleshooting </li>
       </ul>
       </div> 
   </div>


        </div>
    </div>
</div>
{/* End service details area */}



<CallToActionThree />

<PrimaryFooter />
</div>
      
    </>
  )
}

export default AvionicsInstallation


