import React from 'react'
import HeaderOne from "../components/header/HeaderOne";
import Breadcrumb from "../inner/Breadcrumb";
import CallToActionThree from '../components/calltoaction/CallToActionThree';
import PrimaryFooter from '../components/footer/PrimaryFooter';
import ProductsSidebar from './ProductsSidebar';

const RuggedWorkstations = () => {
    const breadcrumbs = [
        { label: 'Home', link: '/' },
        { label: 'Rugged Workstations' }
    ];
  return (
    <>  
    <div className=''>
<HeaderOne />
<Breadcrumb title="Rugged Workstations" breadcrumbs={breadcrumbs} />

{/* start service details area */}
<div className="rts-service-details-area rts-section-gap">
    <div className="container">
        <div className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-12">
                {/* service details left area start */}
                <div className="service-detials-step-1">
                    <h4 className="title"> Rugged Workstations </h4>
                    <p className="disc"> Planetronix Rugged Intel Core Series Workstations are designed to exceed your expectations in any environment. Tested across military, industrial, and commercial programs on sea, land and in air. Certified to comprehensive Military and Industrial Standards (MIL-STD-810, CE, FCC, etc.) for added compatibility, hardened security, and undeniable reliability. Amity provides COTS & Customized Rugged workstations for your desired applications.  </p>
                </div>  
<div className='row'> 
    <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
        <h5 className='title'> Intel Core i Series i3/i5/i7 CPU </h5>
        <p> Extended performance using Intel Core Series Processor up to 3.8GHz Clock rate, 8 Cores (16 Threads), 18M Cache, and up to 85W TDP. </p>
         </div>

    <div className='col-xl-6 col-lg-6 col-md-6 col-12'>
        <img src='../../assets/images/products/processor.jpg' alt='processor'  />
          </div>



          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 

<img src='../../assets/images/products/processor.jpg' alt='processor'  />

</div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> Expandable Memory </h5>
          <p className="disc"> Maximize your memory with twelve carefully optimized DIMMs across 4 channels and up to 64 GB DDR4 2400MHz memory. The best server class RAM in Workstation. </p>

          </div>
 

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> SATA Storage  </h5>
          <p className="disc"> Extend your Storage up to 14 SATA 6Gb/s front access SSD drive bays with hot swap drives. You can expand your storage even further with addition SSD/HDD SATA drive slots. </p>

          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/storage.jpg' alt='processor'  />
          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/add-on-cards.jpg' alt='processor'  />
          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 

          <h5 className='title'> Add-On-Cards PCIe Slots  </h5>
          <p className="disc"> Up to 7 PCIe Gen 3 Slots with high bandwidth and even more PCIe slots can be installed to our rugged server with PCIe Expansion kit. </p>

          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> Power  </h5>
          <p className="disc"> Up to 1500W (AC) power supply or 800W (DC) and even two power supply units into one 3U / 4U / 5U Servers and can run the psu for most demanding applications.

</p>
 </div>

 <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/power.jpg' alt='power'  />
          </div>

          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <img src='../../assets/images/products/iports.jpg' alt='processor'  />
          </div>
          <div className='col-xl-6 col-lg-6 col-md-6 col-12'> 
          <h5 className='title'> I/O Options  </h5>
         <ul>
<li> 1 / 10Gbe LAN MIL-STD Circular Port  </li>
<li> USB 2.0 or 3.0 MIL-STD Circular / Panel
</li>
<li> SATA/600 Ports with Raid Add-On-Card through SSD drive bay
</li>
<li> HDMI / DVI Panel Mount Video Ports
</li>
<li>  RS 232 /422/485 Serial port D-Sub ports
</li>

         </ul>
 </div>
     </div>


            </div>
            {/*rts blog wizered area */}
            <div className="col-xl-4 col-md-12 col-sm-12 col-12 mt_lg--60 pl--50 pl_md--0 pl-lg-controler pl_sm--0">
              
<ProductsSidebar />

                </div>
            {/* rts- blog wizered end area */}
        </div>
    </div>
</div>
{/* End service details area */}




<CallToActionThree/>

<PrimaryFooter/>
</div>



     
    </>
  )
}

export default RuggedWorkstations