import React from 'react'
import { Link } from 'react-router-dom';

const ProductsSidebar = () => {
  return (
   <>
     <div className="rts-single-wized Categories service">
                    <div className="wized-header">
                        <h5 className="title"> Products </h5>
                    </div>
                    <div className="wized-body">
 {/* single categoris */}
 <ul className="single-categories">
 <li> <Link to={'/aviation-products'}> Aviation Products <i className="far fa-long-arrow-right" /> </Link> </li>
 <li> <Link to={'/rugged-displays'}> Rugged Displays <i className="far fa-long-arrow-right" /> </Link> </li>
 <li> <Link to={'/rugged-kvm-displays'}> Rugged KVM Displays  <i className="far fa-long-arrow-right" /> </Link> </li>
 <li> <Link to={'/rugged-panel-pcs'}> Rugged Panel PCs <i className="far fa-long-arrow-right" /> </Link> </li>
 <li> <Link to={'/rugged-workstations'}> Rugged Workstations <i className="far fa-long-arrow-right" /> </Link> </li>
 <li> <Link to={'/rugged-servers'}> Rugged Servers <i className="far fa-long-arrow-right" /> </Link> </li>
 <li> <Link to={'/rugged-ethernet-switches'}> Rugged Ethernet Switches <i className="far fa-long-arrow-right" /> </Link> </li>
     </ul>
                        {/* single categoris End */}       
                    </div>
                </div> 
   
   
   </>
  )
}

export default ProductsSidebar